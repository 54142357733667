<template>
    <v-container>
      <h1 class="text-center">Historische Daten</h1>
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="historicalData"
        :multi-sort="true"
        :sort-by="['year', 'month', 'points']"
        :sort-desc="[true, true, true]"
        class="elevation-1"
      >
      </v-data-table>
    </v-container>
  </template>

<script>
import BackendService from '@/services/BackendService';

export default {
  name: 'HistoricalData',
  data: () => ({
    historicalData: [],
    search: null,
    headers: [
      { text: 'Viewer', value: 'user_name', align: 'center' },
      { text: 'Punkte', value: 'points', align: 'center' },
      { text: 'Monat', value: 'month', align: 'center' },
      { text: 'Jahr', value: 'year', align: 'center' },
    ],
  }),
  mounted() {
    BackendService.loadHistoricalData(1).then((data) => {
      this.historicalData = data.data;
    }).catch((err) => {
      console.error('Fehler beim Laden der historischen Daten:', err);
    });
  },
};
</script>
<style scoped>
</style>
