<template>
  <v-container>
    <h1 class="text-center">Loyality Points Settings</h1>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Waehrung"
          v-model="textValue1"
          maxLength="32"
          counter="32"
          :hint="description1" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Betrag"
          type="Number"
          v-model="textValue2"
          :hint="description2" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-label>Gewinnchance: ({{ sliderValueWinRate }}%)</v-label>
        <v-slider
          :hint="sliderDescriptionWinRate" persistent-hint
          v-model="sliderValueWinRate"
          step="1"
          max="100"
          min="1"
        ></v-slider>
      </v-col>
      <v-col cols="12"></v-col>
      <v-col cols="12">
        <v-label>Subscriber Tier 1 Multiplikator ({{ sliderValueT1 }}x)</v-label>
        <v-slider
          :hint="sliderDescriptionT1" persistent-hint
          v-model="sliderValueT1"
          step="0.1"
          max="10"
          min="1"
        ></v-slider>
      </v-col>
      <v-col cols="12">
        <v-label>Subscriber Tier 2 Multiplikator ({{ sliderValueT2 }}x)</v-label>
        <v-slider
          :hint="sliderDescriptionT2" persistent-hint
          v-model="sliderValueT2"
          step="0.1"
          max="10"
          min="1"
        ></v-slider>
      </v-col>
      <v-col cols="12">
        <v-label>Subscriber Tier 3 Multiplikator ({{ sliderValueT3 }}x)</v-label>
        <v-slider
          :hint="sliderDescriptionT3" persistent-hint
          v-model="sliderValueT3"
          step="0.1"
          max="10"
          min="1"
        ></v-slider>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Follow"
          type="Number"
          v-model="textValue3"
          :hint="description3" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Donation"
          type="Number"
          v-model="textValue4"
          :hint="description4" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="SubscriberT1"
          type="Number"
          v-model="textValue5"
          :hint="description5" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="SubscriberT2"
          type="Number"
          v-model="textValue10"
          :hint="description10" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="SubscriberT3"
          type="Number"
          v-model="textValue11"
          :hint="description11" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Cheer"
          type="Number"
          v-model="textValue6"
          :hint="description6" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-text-field
          :label="Gamble"
          type="Number"
          v-model="textValue7"
          :hint="description7" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12">
      <h3 class="text-center">Songrequest Setting mit Loyality Points</h3>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Songrequest"
          type="Number"
          v-model="textValue8"
          :hint="description8" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="Songrequest_Vips"
          type="Number"
          v-model="textValue9"
          :hint="description9" persistent-hint>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" @click="saveValues">Speichern</v-btn>
      </v-col>
      <LoyaltyCard
        v-if="showSavedValues && settings.length > 0"
        :settings="settings[0]"
      />
    </v-row>
    <v-snackbar
      v-model="LoyaltiySettingsSaved"
      timeout="3000"
      color="green"
    >
      Settings gespeichert!

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="LoyaltiySettingsSaved = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style>
.value-label {
  margin-bottom: 8px;
}

.saved-values-card {
  width: 80vw;
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  padding: 16px;
}

.saved-value-label {
  margin-bottom: 8px;
}
</style>

<script>

import BackendService from '@/services/BackendService';
import LoyaltyCard from '@/components/LoyaltyCard.vue';

export default {
  components: { LoyaltyCard },
  data() {
    return {
      settings: [],
      sliderValueT1: 1,
      sliderValueT2: 1,
      sliderValueT3: 1,
      sliderValueWinRate: 50,
      textValue1: '',
      textValue2: '',
      textValue3: '',
      textValue4: '',
      textValue5: '',
      textValue6: '',
      textValue7: '',
      textValue8: '',
      textValue9: '',
      textValue10: '',
      textValue11: '',
      sliderDescriptionT1: 'Wie hoch soll der Subscriber Tier 1 Multiplikator sein.',
      sliderDescriptionT2: 'Wie hoch soll der Subscriber Tier 2 Multiplikator sein.',
      sliderDescriptionT3: 'Wie hoch soll der Subscriber Tier 3 Multiplikator sein.',
      sliderDescriptionWinRate: 'Wie hoch ist die Gewinnchance beim Gamblen.',
      description1: 'Wie sollen deine Treuepunkte heißen.',
      description2: 'Wie viel Punkte sollen die Viewer pro 10 min bekommen.',
      description3: 'Wie viel Bonuspunkte soll der Viewer bei einem Follow bekommen.',
      description4: 'Wie viel Bonuspunkte soll der Viewer pro 1€ Donation auf deinem Kanal bekommen.',
      description5: 'Wie viel Bonuspunkte soll der Viewer pro Sub auf Stufe 1 auf deinem Kanal bekommen.',
      description6: 'Wie viel Bonuspunkte soll der Viewer pro 100 Bits auf deinem Kanal bekommen.',
      description7: 'Wie viel Punkte soll der Viewer mindestens pro Gamble versuch setzen.',
      description8: 'Wie viel ein Songrequest für normale Viewer kostet.',
      description9: 'Wie viel ein Songrequest für Vips/Mods kostet.',
      description10: 'Wie viel Bonuspunkte soll der Viewer pro Sub auf Stufe 2 auf deinem Kanal bekommen.',
      description11: 'Wie viel Bonuspunkte soll der Viewer pro Sub auf Stufe 3 auf deinem Kanal bekommen.',
      Waehrung: 'Name der Währung',
      Betrag: 'Betrag',
      Follow: 'Follower Bonus',
      Donation: 'Donation Bonus',
      SubscriberT1: 'Abonnenten Stufe 1 Bonus',
      SubscriberT2: 'Abonnenten Stufe 2 Bonus',
      SubscriberT3: 'Abonnenten Stufe 3 Bonus',
      Cheer: 'Cheer Bonus',
      Gamble: 'Gamble Limit',
      Songrequest: 'Songrequest',
      Songrequest_Vips: 'Songrequest für Vips/Mods',
      showSavedValues: true,
      savedValues: {},
      tableHeaders: [
        { text: 'Eigenschaft', value: 'property' },
        { text: 'Wert', value: 'value' },
      ],
    };
  },
  computed: {
    formattedSliderValue() {
      console.log(this.sliderValue.toLocaleString());
      return this.sliderValue.toLocaleString();
    },
  },
  mounted() {
    BackendService.loadLoyalitySettings(1).then((data) => {
      const responseData = data.data;
      if (typeof responseData === 'object') {
        this.settings = [responseData]; // Objekt in ein Array umwandeln
        // console.log(this.settings); // Überprüfe das gesamte settings-Array
        this.textValue1 = this.settings[0]?.name;
        this.textValue2 = this.settings[0]?.amount;
        this.sliderValueWinRate = this.settings[0]?.win_rate;
        this.sliderValueT1 = this.settings[0]?.sub_multiplier_t1;
        this.sliderValueT2 = this.settings[0]?.sub_multiplier_t2;
        this.sliderValueT3 = this.settings[0]?.sub_multiplier_t3;
        this.textValue3 = this.settings[0]?.follow_bonus;
        this.textValue4 = this.settings[0]?.tip_bonus;
        this.textValue5 = this.settings[0]?.sub_bonus_t1;
        this.textValue10 = this.settings[0]?.sub_bonus_t2;
        this.textValue11 = this.settings[0]?.sub_bonus_t3;
        this.textValue6 = this.settings[0]?.cheer_bonus;
        this.textValue7 = this.settings[0]?.gamble_limit;
        this.textValue8 = this.settings[0]?.sr;
        this.textValue9 = this.settings[0]?.vip_sr;
        // console.log(this.sliderValue);
      } else {
        // console.error('Die geladenen Daten sind kein Objekt:', responseData);
      }
    });
  },
  methods: {
    saveValues() {
      BackendService.saveLoyalitySettings({
        name: this.textValue1,
        amount: this.textValue2,
        win_rate: this.sliderValueWinRate,
        sub_multiplier_t1: this.sliderValueT1,
        sub_multiplier_t2: this.sliderValueT2,
        sub_multiplier_t3: this.sliderValueT3,
        follow_bonus: this.textValue3,
        tip_bonus: this.textValue4,
        sub_bonus_t1: this.textValue5,
        sub_bonus_t2: this.textValue10,
        sub_bonus_t3: this.textValue11,
        cheer_bonus: this.textValue6,
        gamble_limit: this.textValue7,
        sr: this.textValue8,
        vip_sr: this.textValue9,
        variant: 1,
      }).then((data) => {
        this.settings = [data.data];
        this.LoyaltiySettingsSaved = true;
        // console.log(this.settings);
      }).catch(() => {
        alert('nö!');
      });
    },
  },
};
</script>
