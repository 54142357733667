<template>
  <v-container>
    <h1 class="text-center">Giveaway</h1>
    <v-toolbar
      flat
    > <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    </v-toolbar>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="giveaways"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          max-width="80vw"
        >
          <v-card>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editedItem.title"
                      label="Titel"
                      :rules="[v => !!v || 'Titel is required']"
                    ></v-text-field>
                  </v-col>

                    <v-col
                    cols="12"
                    >
                      <v-textarea
                        v-model="editedItem.description"
                        label="Beschreibung"
                        rows="1"
                        auto-grow
                        :rules="[v => !!v || 'Beschreibung is required']"
                      ></v-textarea>
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-checkbox v-model="editedItem.confirm_buyin" label="Kauf bestätigung" />
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      v-model="editedItem.cost_per_ticket"
                      label="Ticketkosten"
                      type="Number"
                      :rules="[v => !!v || 'Ticketkosten is required']"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      v-model="editedItem.ticker_per_user"
                      label="Höchstzahl der Tickets pro Benutzer"
                      :rules="[v => !!v || 'Höchstzahl der Tickets pro Benutzer is required']"
                      type="Number"
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-label>Subscriber Multiplikator ({{ editedItem.sub_multiplier }}x)</v-label>
                    <v-slider
                      :hint="sliderDescription" persistent-hint
                      v-model="editedItem.sub_multiplier"
                      step="1"
                      max="10"
                      min="1"
                    ></v-slider>
                  </v-col>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :loading="saving"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Sicher dieses Giveaway löschen?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Nein!</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja!</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog class ="justify-content=center" v-model="dialogWinner" max-width="500px">
          <v-card class="text-center">
            <v-card-title class="text-h5 ">
              Der Gewinner dieses Giveaways ist:<br>{{ winner }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click=
                "pickWinner(safeWinnerItem)">Reroll!</v-btn>
              <v-btn color="blue darken-1" text @click=
                "closeGiveawayWinnerDialog">Verstanden!</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.confirm_buyin`]="{ item }">
          <div>
            {{ item.confirm_buyin === 1 ? 'Ja' : 'Nein' }}
          </div>
      </template>
      <template v-slot:[`item.started`]="{ item }">
          <div v-if="item.started">
            {{ new Date(item.started * 1000).toLocaleString('de-De') }}
          </div>
          <i v-else>
            -
          </i>
      </template>
      <template v-slot:[`item.stopped`]="{ item }">
        <div v-if="item.stopped">
          {{ new Date(item.stopped * 1000).toLocaleString('de-De') }}
        </div>
        <i v-else>
          -
        </i>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              v-bind="attrs" v-on="on"
           >
             mdi-pencil
            </v-icon>
          </template>
          <span>Editieren!</span>
        </v-tooltip>
        <v-tooltip top color="warning">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="copyItem(item)"
              v-bind="attrs" v-on="on"
           >
             mdi-content-copy
            </v-icon>
          </template>
          <span>Kopieren!</span>
        </v-tooltip>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              :disabled="item.started ==! null && item.stopped === null"
              @click="deleteItem(item)"
              v-bind="attrs" v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Löschen!</span>
        </v-tooltip>
        <v-tooltip top color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="startItem(item)"
              :disabled="item.started !== null"
              v-bind="attrs" v-on="on"
            >
              mdi-play
            </v-icon>
          </template>
          <span>Starten!</span>
        </v-tooltip>
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="stopItem(item)"
              :disabled="item.started === null || item.stopped !== null"
              v-bind="attrs" v-on="on"
            >
              mdi-stop
            </v-icon>
          </template>
          <span>Stoppen!</span>
        </v-tooltip>
        <v-tooltip top color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="winner ? dialogWinner = true : pickWinner(item)"
              :disabled="item.started === null || item.stopped === null"
              v-bind="attrs" v-on="on"
            >
              mdi-ticket
            </v-icon>
            </template>
          <span>Gewinner ziehen!</span>
        </v-tooltip>
        <a :href="`/giveaway/${item.publicid}`" target="_blank">
        <v-tooltip top color="success">
          <template v-slot:activator="{ on, attrs }">
              <v-icon
              small
              class="mr-2"
              v-bind="attrs" v-on="on"
            >
              mdi-link
            </v-icon>
          </template>
          <span>Link zum Giveaway!</span>
        </v-tooltip>
        </a>
      </template>
    </v-data-table>
    <v-btn
      color="success"
      class="mr-4"
      @click="newGiveaway"
    >
      neues Giveaway
    </v-btn>

  </v-container>
</template>

<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'GiveawayView',

  data: () => ({
    safeWinnerItem: {},
    winner: '',
    search: '',
    dialog: false,
    dialogDelete: false,
    dialogWinner: false,
    saving: false,
    giveaways: [],
    valid: true,
    sliderDescription: 'Abonnentengewinnchancemultiplikator',
    headers: [
      { text: 'Titel', value: 'title', align: 'center' },
      { text: 'Beschreibung', value: 'description', align: 'center' },
      { text: 'Ticketkosten', value: 'cost_per_ticket', align: 'right' },
      { text: 'Ticket/Benutzer', value: 'ticker_per_user', align: 'right' },
      { text: 'Bestätigen', value: 'confirm_buyin', align: 'center' },
      { text: 'Sub-Multiplikator', value: 'sub_multiplier', align: 'right' },
      { text: 'Start', value: 'started', align: 'center' },
      {
        text: 'Stop', value: 'stopped', align: 'center', sortable: false,
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
    },
    defaultItem: {
    },
  }),
  components: {
  },
  mounted() {
    BackendService.loadGiveaways().then((data) => {
      this.giveaways = data.data;
    });
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.giveaways.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    copyItem(item) {
      this.editedItem = { ...item };
      delete this.editedItem.id;
      this.dialog = true;
    },
    newGiveaway() {
      this.editedIndex = -1;
      this.editedItem = { };
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    deleteItemConfirm() {
      BackendService.deleteGiveaway({
        id: this.editedItem.id,
      }).then((data) => {
        this.giveaways = data.data;
        this.closeDelete();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.giveaways.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    startItem(item) {
      BackendService.startGiveaway({
        id: item.id,
        title: item.title,
      }).then((data) => {
        this.giveaways = data.data;
      });
    },
    stopItem(item) {
      BackendService.stopGiveaway({
        id: item.id,
        title: item.title,
      }).then((data) => {
        this.giveaways = data.data;
      });
    },
    pickWinner(item) {
      if (this.dialogWinner === true) this.dialogWinner = false;
      BackendService.pickWinnerGiveaway({
        id: item.id,
      }).then((data) => {
        // console.log(data.data);
        const targetArray = data.data.find((item1) => item1.id === item.id);
        // console.log(targetArray);
        this.giveaways = data.data;
        this.winner = targetArray.winner;
        this.safeWinnerItem = item;
        this.dialogWinner = true;
      });
    },
    closeGiveawayWinnerDialog() {
      this.dialogWinner = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        // console.log(this.editedItem);
        BackendService.saveGiveaway(
          this.editedItem,
        ).then((data) => {
          this.giveaways = data.data;
        }).catch(() => {
          alert('nö!');
        }).finally(() => {
          this.saving = false;
          this.close();
        });
      }
    },
  },
};
</script>
