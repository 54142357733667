import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKENDSERVICE_BASEURL,
  withCredentials: true,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
  },
});

export default {
  checkStatus(loggedIn, db, tiktokDb) {
    return apiClient.post('/status', { loggedIn, db, tiktokDb }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  checkStatus2(hostId, tikokHostId, userid, instanz) {
    return apiClient.post('/status2', {
      hostId, tikokHostId, userid, instanz,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  login(username, password) {
    return apiClient.post('/authenticate', { username, password }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  logout() {
    return apiClient.get('/logout');
  },
  loadCommand(variant) {
    return apiClient.post('/getCommands', { variant }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveCommand(data) {
    return apiClient.post('/saveCommand', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadCustomCommand(variant) {
    return apiClient.post('/getCustomCommands', { variant }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveCustomCommand(data) {
    return apiClient.post('/saveCustomCommand', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  insertCustomCommand(data) {
    return apiClient.post('/insertCustomCommand', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  deleteCustomCommand(data) {
    return apiClient.post('/deleteCustomCommand', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadCommandSettings(data) {
    return apiClient.post('/getCommandSettings', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveCommandSettings(data) {
    return apiClient.post('/saveCommandSettings', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadSubathonTimerSettings() {
    return apiClient.get('/getSubathonTimerSettings', { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveSubathonTimerSettings(data) {
    return apiClient.post('/saveSubathonTimerSettings', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadGiveaways() {
    return apiClient.get('/getGiveaways');
  },
  saveGiveaway(data) {
    return apiClient.post('/saveGiveaway', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveGiveawayNoPoints(data) {
    return apiClient.post('/saveGiveawayNoPoints', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  deleteGiveaway(data) {
    return apiClient.post('/deleteGiveaway', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  startGiveaway(data) {
    return apiClient.post('/startGiveaway', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  startGiveawayNoPoints(data) {
    return apiClient.post('/startGiveawayNoPoints', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  stopGiveaway(data) {
    return apiClient.post('/stopGiveaway', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  stopGiveawayNoPoints(data) {
    return apiClient.post('/stopGiveawayNoPoints', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveSwitchStateCommands(data) {
    return apiClient.post('/saveSwitchStateCommands', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveSwitchStateCCommands(data) {
    return apiClient.post('/saveSwitchStateCCommands', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveVisibleStateCommands(data) {
    return apiClient.post('/saveVisibleStateCommands', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveLoyalitySettings(data) {
    return apiClient.post('/saveLoyalitySettings', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadLoyalitySettings(variant) {
    return apiClient.post('/loadLoyalitySettings', { variant }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadSingleGiveaway(id) {
    return apiClient.post('/loadSingleGiveaway', { id }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  pickWinnerGiveaway(data) {
    return apiClient.post('/pickWinnerGiveaway', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadCommandList(pubId) {
    return apiClient.post('/commandlist', { pubId }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loginWithCode(code) {
    return apiClient.post('/loginWithCode', { code }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadRewards() {
    return apiClient.get('/loadRewards');
  },
  loadGiveawaysNoPoints() {
    return apiClient.get('/loadGiveawaysNoPoints');
  },
  saveSwitchStateCp(data) {
    return apiClient.post('/saveSwitchStateCp', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  updateReward(data) {
    return apiClient.post('/updateReward', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  createReward(data) {
    return apiClient.post('/createReward', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  deleteReward(data) {
    return apiClient.post('/deleteReward', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  ticketbuy(data) {
    return apiClient.post('/ticketbuy', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadOverlays() {
    return apiClient.get('/overlays');
  },
  createOverlay() {
    return apiClient.post('/createoverlay');
  },
  checkBotId(data) {
    return apiClient.post('/checkBotId', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  selectHostId(data) {
    return apiClient.post('/checkBotId', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  pullOptions(code, state) {
    return apiClient.post('/pullOptions', { code, state }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadGiveawayNoPointsEntrant(data) {
    return apiClient.post('/loadGiveawayNoPointsEntrant', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  pickWinnerGiveawayNoPoints(data) {
    return apiClient.post('/pickWinnerGiveawayNoPoints', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  lastWinnerGiveawayNoPoints(data) {
    return apiClient.post('/lastWinnerGiveawayNoPoints', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  deleteGiveawayNoPoints(data) {
    return apiClient.post('/deleteGiveawayNoPoints', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  deleteGiveawayNoPointsUser(data) {
    return apiClient.post('/deleteGiveawayNoPointsUser', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadOverlay(id) {
    return apiClient.get(`/overlay/${id}`);
  },
  loadSubGoals(id) {
    return apiClient.get(`/loadsubgoals/${id}`);
  },
  loadBitGoals(id) {
    return apiClient.get(`/loadbitgoals/${id}`);
  },
  loadTopDono(id) {
    return apiClient.get(`/loadtopdono/${id}`);
  },
  loadObsToken(data) {
    return apiClient.post('/loadObsToken', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  savePrefix(data) {
    return apiClient.post('/savePrefix', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadPools() {
    return apiClient.get('/loadPools');
  },
  createPool(data) {
    return apiClient.post('/createPool', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  savePoolContent(editedItem, filteredContent) {
    return apiClient.post('/savePoolContent', { editedItem, filteredContent }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  savePoolSettings(data) {
    return apiClient.post('/savePoolSettings', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadLoyaltiyRewards() {
    return apiClient.get('/loadLoyaltiyRewards');
  },
  updateLoyaltiyReward(data) {
    return apiClient.post('/updateLoyaltiyReward', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  createLoyaltiyReward(data) {
    return apiClient.post('/createLoyaltiyReward', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  deleteLoyaltiyReward(data) {
    return apiClient.post('/deleteLoyaltiyReward', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadRewardsPublic(data) {
    return apiClient.post('/loadRewardsPublic', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  redeemRewardsPublic(data) {
    return apiClient.post('/redeemRewardsPublic', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadRewardRequests() {
    return apiClient.get('/loadRewardRequests');
  },
  acceptRewardRequests(data) {
    return apiClient.post('/acceptRewardRequests', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  cancelRewardRequests(data) {
    return apiClient.post('/cancelRewardRequests', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  acceptallRewardRequests(data) {
    return apiClient.post('/acceptallRewardRequests', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  cancelallRewardRequests(data) {
    return apiClient.post('/cancelallRewardRequests', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  deletePool(data) {
    return apiClient.post('/deletePool', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadProfilSettings(data) {
    return apiClient.post('/loadProfilSettings', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveProfilSettings(data) {
    return apiClient.post('/saveProfilSettings', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  createBot(data) {
    return apiClient.post('/createBot', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  sendPaymentConfirmation(data) {
    return apiClient.post('/sendPaymentConfirmation', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  getSubscriptionStep(id) {
    return apiClient.get(`/subscriptionstep/${id}`);
  },
  createSubscription(data) {
    return apiClient.post('/createSubscription', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  checkSpotifyDevice(data) {
    return apiClient.post('/checkSpotifyDevice', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  checkTwitchUser(data) {
    return apiClient.post('/checkTwitchUser', { name: data }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadBotStateData() {
    return apiClient.get('/botStateData');
  },
  updateRefreshToken(data) {
    return apiClient.post('/updateRefreshToken', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  restartBot() {
    return apiClient.get('/restartBot');
  },
  checkRefreshToken() {
    return apiClient.get('/checkRefreshToken');
  },
  saveMonthlySettings(data) {
    return apiClient.post('/saveMonthlySettings', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadMonthlySettings(variant) {
    return apiClient.post('/loadMonthlySettings', { variant }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  saveSwitchStateVariable(data) {
    return apiClient.post('/saveSwitchStateVariable', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
  loadHistoricalData(variant) {
    return apiClient.post('/loadHistoricalData', { variant }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  },
};
