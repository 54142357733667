<template>
  <v-container>
    <h1 class="text-center">Subathon Timer Settings</h1>
    <v-toolbar
      flat
    > <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    </v-toolbar>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="commands"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-dialog
          persistent
          v-model="dialog"
          max-width="80vw"
        >
          <v-card>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="editedItem.event"
                      label="Event"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="editedItem.anz_sec"
                      label="Sekunden pro Event"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :loading="saving"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              v-bind="attrs" v-on="on"
              :disabled="$store.state.user_perm < 6"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editieren!</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="dialog"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <div class="custom-container3">
      <v-row>
        <v-col cols="12" md="12">
          <p class="text-center"> <span style="color: #00ffcd;">Subathon Timer Link:</span>
           {{baseUrl}}/{{obstoken}}</p>
        </v-col>
        <v-col cols="12" md="12">
        <h3 class="text-center">Subathon Timer Commands zusammengefasst:</h3>
        </v-col>
        <v-col cols="12" md="12">
          <p class="text-center"> <span style="color: #00ffcd;">Timer einstellen:</span>
             !timer init 1:00:00</p>
        </v-col>
        <v-col cols="12" md="12">
          <p class="text-center"> <span style="color: #00ffcd;">Timerzeit hinzufügen / abziehen
            in Sekunden:</span>
            !timer add 300 / !timer add -500</p>
        </v-col>
        <v-col cols="12" md="12">
          <p class="text-center"> <span style="color: #00ffcd;">Timer starten / stoppen:</span>
            !timer start / !timer stop</p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'SubathonTimer',
  data: () => ({
    search: '',
    dialog: false,
    obstoken: '',
    baseUrl: '',
    commands: [],
    saving: false,
    headers: [
      { text: 'Event', value: 'event', align: 'center' },
      { text: 'Sekunden pro Event', value: 'anz_sec', align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
    },
    defaultItem: {
    },
  }),
  mounted() {
    this.baseUrl = window.location.origin;
    const subdomain = 'subathon';
    this.baseUrl = this.baseUrl.replace(/:\/\/(.*?)(\/|$)/, `://${subdomain}.$1$2`);
    BackendService.loadSubathonTimerSettings().then((data) => {
      this.commands = data.data;
    });
    BackendService.loadObsToken({
      broadcasterid: this.$store.state.hostId,
    }).then((data) => {
      this.obstoken = data.data.obs_token;
    });
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.commands.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      this.saving = true;
      BackendService.saveSubathonTimerSettings({
        id: this.editedItem.id,
        event: this.editedItem.event,
        anz_sec: this.editedItem.anz_sec,
      }).then((data) => {
        this.commands = data.data;
        this.saving = false;
        this.close();
      });
    },
  },
};
</script>
<style>
.chipGroup label {
  font-size: 12px;
}
</style>
